import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Category/Category.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Home/Home.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/HomeV2/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Login/Login.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/NotFound/NotFound.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Product/Product.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Products/Products.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/ProductV2/ProductV2Screen.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/screens/main/Secondaries/Secondaries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
